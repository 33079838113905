<template>
  <section
    class="home-hero d-flex align-items-center justify-content-center"
    :style="heroStyles"
  >
    <div class="home-hero__inner">
      <h1 class="home-hero__heading">{{ $t('faq.heading') }}</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',
  computed: {
    heroStyles() {
      return {
        'background-image': `url(${require('@/assets/images/home/hero/img-1.jpeg')})`,
      };
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
