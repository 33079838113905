<template>
  <div class="home-page has-hero">
    <landing-header />
    <hero />
    <div class="home-main">
      <f-a-q />
    </div>
    <landing-footer />
  </div>
</template>

<script>
import LandingHeader from '@/views/landing-header/LandingHeader.vue';
import LandingFooter from '@/views/landing-footer/LandingFooter.vue';
import Hero from '@/views/home/hero/Hero.vue';
import FAQ from '@/views/home/faq/FAQ.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    FAQ,
    LandingHeader,
    LandingFooter,
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
