<template>
  <footer class="w-footer">
    <div class="w-footer__container">
      <div class="w-container--full">
        <div class="w-footer__row w-footer__top-row">
          <div class="w-footer__left">
            <div class="w-footer__navs">
              <nav class="w-footer__nav">
                <h3
                class="w-footer__header text--small u-text-bold"
                v-text="$t('general.nawa')"/>
                <ul class="w-footer__list" active-class="active">
                  <li class="w-footer__item">
                    <a
                      :href="`${$appUrl}/${$i18n.locale}/supervisors`"
                      class="link text--small scrollactive-item"
                      v-text="$t('home.info.supervisory')"
                    />
                  </li>
                  <li class="w-footer__item">
                    <a
                      class="link text--small"
                      :href="getSurveyUrl"
                      target="_blank"
                      v-text="$t('home.info.survey')"
                    />
                  </li>
                </ul>
              </nav>
              <nav class="w-footer__nav">
                <h3
                class="w-footer__header text--small u-text-bold"
                v-text="$t('footer.nav_products')"/>
                <ul class="w-footer__list">
                  <li class="w-footer__item">
                    <a
                      class="link text--small"
                      :href="`${$appUrl}/${$i18n.locale}/login`">
                      {{ $t('footer.services') }}
                    </a>
                  </li>
                  <li class="w-footer__item">
                    <a
                      class="link text--small"
                      :href="`${$appUrl}/${$i18n.locale}/check-bank-account-permit`">
                      {{ $t('header.nav_check_bank_account_permit') }}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="w-footer__umbrella">
            <div class="w-footer__logos">
              <div class="w-footer__logo w-footer__logo--margin">
                <a
                  href="https://ncnp.gov.sa/"
                  aria-label="link-takamol"
                  class="link link--default"
                  target="_blank">
                  <img class="w-footer__logo-img w-footer__logo--margin-img"
                       :src="require('@/assets/images/home/footer/center_logo.svg')"
                       alt="National center for non-profit sector"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-container--full u-bg-gray">
        <div class="w-footer__row w-footer__bottom-row">
          <div class="w-footer__info">
            <logo
              :external-url="true"
              :route-name="`${$appUrl}/${$i18n.locale}`"
              class="link w-footer__logo-product"
            />
            <div class="w-footer__copyrights">
              {{ $t('footer.current_year') }}&nbsp;/&nbsp;&copy; {{ $t('general.nawa') }}
            </div>
            <div class="w-footer__languages">
              <div class="language">
                <button
                  @click="setLang($enums.LocaleTypes.En)"
                  :class="{'is-active': $i18n.locale === $enums.LocaleTypes.En}"
                  class="link language__item"
                >English
                </button>
                <button
                  @click="setLang($enums.LocaleTypes.Ar)"
                  :class="{'is-active': $i18n.locale === $enums.LocaleTypes.Ar }"
                  class="link language__item"
                >العربية
                </button>
              </div>
            </div>
          </div>
          <div class="w-footer__socials">
            <ul class="social-icons">
              <li class="social-icon__item">
                <a
                  class="link link--inherit"
                  href="https://www.twitter.com"
                  aria-label="link-twitter"
                  target="_blank">
                  <svg color="#7c8986" viewBox="0 0 32 32" width="16" height="13">
                    <icon-twitter/>
                  </svg>
                </a>
              </li>
              <li class="social-icon__item">
                <a
                  class="link link link--inherit"
                  href="https://www.youtube.com"
                  aria-label="link-youtube"
                  target="_blank">
                  <svg color="#7c8986" viewBox="0 0 47 32" width="16" height="11">
                    <icon-youtube/>
                  </svg>
                </a>
              </li>
              <li class="social-icon__item">
                <a
                  class="link link link--inherit"
                  href="https://www.instagram.com"
                  aria-label="link-instagram"
                  target="_blank">
                  <svg color="#7c8986" viewBox="0 0 34 32" width="13" height="14">
                    <icon-in/>
                  </svg>
                </a>
              </li>
            </ul>
        </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'LandingFooter',
  computed: {
    getSurveyUrl() {
      return 'https://www.questionpro.com/t/ATPjIZnkWk';
    },
  },
  methods: {
    setLang(lang) {
      const { locale } = this.$route.params;
      if (locale && locale !== lang) {
        this.$router.push({
          path: this.$route.fullPath.replace(`/${locale}`, `/${lang}`),
        });
      }
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
