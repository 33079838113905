<template>
  <section
    class="home-faq"
  >
    <collapse-box
      class="home-faq-item"
      animation="slide"
      v-for="(collapse, index) of collapses"
      :key="index"
      :open="isOpen === index"
      @open="isOpen = index">
      <template #trigger="props">
        <div
          class="home-faq-header"
          role="button"
        >
          <div class="home-faq-title">
            {{ $t(collapse.title) }}
          </div>
          <div class="home-faq-icon">
            {{ props.open ? '-' : '+' }}
          </div>
        </div>
      </template>
      <div class="home-faq-content">
        <div v-if="collapse.html" v-html="collapse.text"></div>
        <div v-else>{{ $t(collapse.text) }}</div>
      </div>
    </collapse-box>
  </section>
</template>

<script>
import CollapseBox from '@/components/collapse-box/CollapseBox.vue';

export default {
  name: 'FAQ',
  components: { CollapseBox },
  data() {
    return {
      isOpen: false,
      collapses: [
        {
          title: 'faq.title.q1',
          text: 'faq.text.a1',
          html: false,
        },
        {
          title: 'faq.title.q2',
          text: 'faq.text.a2',
          html: false,
        },
        {
          title: 'faq.title.q3',
          text: 'faq.text.a3',
          html: false,
        },
        {
          title: 'faq.title.q4',
          text:
            '<a target="_blank" href="https://www.snapchat.com/add/nawa_gov">snapchat</a><br/><a target="_blank" href="https://mobile.twitter.com/NAWA_gov">twitter</a><br/><a target="_blank" href="https://instagram.com/nawa_gov?r=nametag">instagram</a><br/><a target="_blank" href="https://www.linkedin.com/in/nawa-sa-3007a5212/">linkedin</a>',
          html: true,
        },
        {
          title: 'faq.title.q5',
          text: 'faq.text.a5',
          html: false,
        },
        {
          title: 'faq.title.q6',
          text: 'faq.text.a6',
          html: false,
        },
        {
          title: 'faq.title.q7',
          text: 'faq.text.a7',
          html: false,
        },
        {
          title: 'faq.title.q8',
          text: 'يمكن للمستفيدين التسجيل في منصة نوى من خلال الرابط التالي : <a href="https://beta.nawa.gov.sa/ar/registration">https://beta.nawa.gov.sa/ar/registration</a>',
          html: true,
        },
      ],
    };
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
