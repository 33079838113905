<template>
  <FixedHeader :fixed.sync="isFixed" :class="{open: isMobOpened}">
    <div class="sticky-outer-wrapper" :class="{ 'is-sticky': isFixed }">
      <div class="sticky-inner-wrapper">
        <header class="w-header">
          <div class="w-header__mobile w-container--full">
            <logo
              :external-url="true"
              :route-name="`${$appUrl}/${$i18n.locale}`"
              class="link w-header__mobile-logo"
            />
            <svg
              class="ham-menu hamRotate"
              :class="{active: isMobOpened}"
              viewBox="0 0 100 100"
              width="50"
              @click="toggleMenu"
            >
              <!-- eslint-disable -->
              <g>
                <path
                  class="line top"
                  d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"/>
                <path
                  class="line middle"
                  d="m 30,50 h 40"/>
                <path
                  class="line bottom"
                  d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"/>
              </g>
              <!-- eslint-enable -->
            </svg>
          </div>
          <div
            class="w-header__bar w-header__container w-container--full"
            :class="{ 'w-header__bar--opened': isMobOpened }"
          >
            <div class="w-header__info">
              <div class="w-header__logo">
                <logo
                  :external-url="true"
                  :route-name="`${$appUrl}/${$i18n.locale}`"
                  class="link link--default"
                />
              </div>
            </div>
            <div class="w-header__nav">
              <div class="w-nav">
                <ul class="w-nav__list">
                  <li class="w-nav__item" @click="isMobOpened = false">
                    <a
                      :href="`${$appUrl}/${$i18n.locale}/partners`"
                      class="link w-nav__link w-nav__link-text"
                      v-text="$t('header.nav_partners')"
                    />
                  </li>
                  <li class="w-nav__item" @click="isMobOpened = false">
                    <a
                      :href="`${$appUrl}/${$i18n.locale}/check-bank-account-permit`"
                      class="link w-nav__link w-nav__link-text"
                      v-text="$t('header.nav_check_bank_account_permit')"
                    />
                  </li>
                  <li class="w-nav__item" @click="isMobOpened = false">
                    <a
                      :href="`${$appUrl}/${$i18n.locale}/entities-list`"
                      class="link w-nav__link w-nav__link-text"
                      v-text="$t('header.nav_established_entities')"
                    />
                  </li>
                  <li class="w-nav__item" @click="isMobOpened = false">
                    <a
                      :href="`${$appUrl}/${$i18n.locale}/#products`"
                      class="link w-nav__link w-nav__link-text scrollactive-item"
                      v-text="$t('header.nav_services')"
                    />
                  </li>
                  <li class="w-nav__item">
                    <a
                      :href="`${$appUrl}/${$i18n.locale}/login`"
                      class="link w-nav__link w-nav__link-text"
                      v-text="$t('header.nav_signin')"
                    />
                  </li>
                  <mq-layout mq="laptop-">
                    <nav class="w-header__languages">
                      <div class="language">
                        <button
                          @click="setLang($enums.LocaleTypes.En)"
                          :class="{'is-active': $i18n.locale === $enums.LocaleTypes.En}"
                          class="link language__item">English
                        </button>
                        <button
                          @click="setLang($enums.LocaleTypes.Ar)"
                          :class="{'is-active': $i18n.locale === $enums.LocaleTypes.Ar }"
                          class="link language__item">العربية
                        </button>
                      </div>
                    </nav>
                  </mq-layout>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  </FixedHeader>
</template>

<script>
import FixedHeader from 'vue-fixed-header';

export default {
  name: 'LandingHeader',
  components: {
    FixedHeader,
  },
  data() {
    return {
      isFixed: false,
      isMobOpened: false,
      isDropOpened: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMobOpened = !this.isMobOpened;
    },
    setLang(lang) {
      const { locale } = this.$route.params;
      if (locale && locale !== lang) {
        this.$router.push({
          path: this.$route.fullPath.replace(`/${locale}`, `/${lang}`),
        });
      }
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
